import React, { useContext, useEffect, useState } from 'react'
import CustomImage from '../../components/customImage/customImage'
import useTemplateHook from '../../components/template/templateHook'
import { SubscriptionContext } from '../../context/subscription'
import TickIcon from '../../assets/orange-svtv-tick.svg'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const PricingPlans = () => {
  const { getTemplateImage, isLogin } = useTemplateHook()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const { availableSubscription } = useContext(SubscriptionContext)
  const [searchParams] = useSearchParams()
  let paramEmail = searchParams.get('email', '')

  useEffect(() => {
    if (paramEmail) {
      setEmail(paramEmail)
    }
  }, [paramEmail])

  let subscriptionData = {
    data679908a429ecdaf7aed8f23f: {
      img: `public/common-template-assets/svtv-withads-img179787132.png`,
      price: '$5.99/month',
      other_info: ['with ads', 'full library'],
      description: `Start your 1 month Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
    data6799ddd829ecdaf7aed8f247: {
      img: `public/common-template-assets/svtv-plus-withoutads-img179787132.png`,
      price: '$9.99/month',
      other_info: ['without ads', 'full library', 'Live TV'],
      description: `Start your 1 month Ad Free Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
    data679def2629ecdaf7aed8f269: {
      img: `public/common-template-assets/svtv-yearly-withads-img179787132.png`,
      price: '$49.99/year',
      other_info: ['with ads', 'full library', 'Live TV'],
      description: `Start your 1 year Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
    data679def6629ecdaf7aed8f26a: {
      img: `public/common-template-assets/svtv-yearly-withoutads-img172413354.png`,
      price: '$59.99/year',
      other_info: ['without ads', 'full library', 'Live TV'],
      description: `Start your 1 year Ad Free Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
  }

  const handleSubRedirect = (id) => {
    if (isLogin) {
      navigate(`/payment/${id}`)
      return
    }
    if (email) {
      navigate(`/register-plan/${id}?email=${email}`)
      return
    }
    navigate(`/register-plan/${id}`)
  }

  return (
    <div style={{ background: '#000' }} className="w-full h-full xl:px-[8.489%] py-10">
      <div className=" flex flex-col items-center xl:items-start">
        <CustomImage
          imageUrl={getTemplateImage(
            `public/common-template-assets/svtv-footer-logo1789782343.png`,
          )}
          imgClass="w-[110px] xl:w-[194px] cursor-pointer"
          imgStyle={{ backgroundColor: 'transparent', aspectRatio: '1.8644' }}
          loaderClass="w-[110px] xl:w-[194px] cursor-pointer"
          loaderStyle={{ background: '#5A5A5A50', aspectRatio: '1.8644' }}
          imgAlt={'Logo'}
        />
        <p className="mb-0 mt-10 text-white xl:text-[22px]">Select a membership:</p>
      </div>
      <div className=" flex flex-col items-center lg:flex-row justify-between mt-4 xl:mt-8">
        {!isEmpty(availableSubscription) &&
          [...availableSubscription]
            .filter((e, i) => i < 4)
            .map((info, index) => {
              return (
                <div
                  className={`mt-4 xl:mt-0 w-[356px] lg:w-[24.3%] cursor-pointer flex flex-col items-center pb-[30px] xl:self-stretch`}
                  style={{
                    backgroundImage: 'linear-gradient(to bottom, #111, #1c1c1c)',
                  }}
                  key={index}
                >
                  <CustomImage
                    imageUrl={getTemplateImage(subscriptionData[`data${info._id}`]?.img)}
                    imgClass="w-full cursor-pointer"
                    imgStyle={{ backgroundColor: 'transparent', aspectRatio: '356/249' }}
                    loaderClass="w-full cursor-pointer"
                    loaderStyle={{ background: '#5A5A5A50', aspectRatio: '356/249' }}
                    imgAlt={'Logo'}
                  />

                  <div className="flex h-full w-full flex-col items-center justify-between">
                    <div className="flex w-full flex-col items-center text-white">
                      <p className="text-[38px] xl:text-[2.083vw] text-center font-bold mt-2">
                        {subscriptionData[`data${info._id}`]?.price}
                      </p>
                      {subscriptionData[`data${info._id}`]?.other_info.map((ele, idx) => {
                        return (
                          <div className="flex items-center xl:text-lg 2xl:text-xl mt-2" key={idx}>
                            <img src={TickIcon} alt="TickIcon" className="mr-1" />
                            {ele}
                          </div>
                        )
                      })}
                    </div>
                    <div className="mt-4 flex flex-col items-center w-full">
                      <p style={{ color: '#959595' }} className="text-sm text-center px-8 mb-0">
                        {subscriptionData[`data${info._id}`]?.description}
                      </p>
                      <div
                        className="w-[199px] mt-6 flex justify-center items-center rounded-full cursor-pointer h-[40px] "
                        style={{
                          color: '#fff',
                          backgroundImage: 'linear-gradient(to right, #ec4614 9%, #f97607 90%)',
                        }}
                        onClick={() => handleSubRedirect(info._id)}
                      >
                        {`SUBSCRIBE NOW`}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )
}

export default PricingPlans

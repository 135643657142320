import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import useTemplateHook from '../../components/template/templateHook'
import TickIcon from '../../assets/orange-svtv-tick.svg'
import LockIcon from '../../assets/green-lock.svg'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import CustomImage from '../../components/customImage/customImage'
import { useSearchParams } from 'react-router-dom'

const RegisterPlan = () => {
  let planId = useParams().planId
  const { t } = useTranslation()
  const { getTemplateImage, setLoader, setUser, LOGIN_API, SIGNUP_API } = useTemplateHook()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorData, setErrorData] = useState({})
  const [searchParams] = useSearchParams()
  let paramEmail = searchParams.get('email', '')

  useEffect(() => {
    if (paramEmail) {
      setEmail(paramEmail)
    }
  }, [paramEmail])

  const [{ data: loginRes, loading: loginResLoading }, loginCall] = useAxios(
    {
      url: LOGIN_API,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: signUpRes, loading: signUpLoading }, signUpCall] = useAxios(
    {
      url: SIGNUP_API,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (loginRes && loginRes.success) {
      setUser(loginRes?.data, false, true)
      navigate(`/payment/${planId}`)
    }
  }, [loginRes])

  useEffect(() => {
    if (signUpRes && signUpRes?.success) {
      // raiseToast('success', t('register_success_toast'))
      loginCall({
        data: {
          email_id: email,
          password: password,
        },
      })
    }
  }, [signUpRes])

  useEffect(() => {
    setLoader(loginResLoading || signUpLoading)
  }, [loginResLoading, signUpLoading])

  const validateInfo = (type) => {
    let isFormValid = true
    let temp = {}

    if (name.trim().length === 0) {
      isFormValid = false
      temp.name = `${t('user_name_empty')}!`
    }
    if (email.trim().length === 0) {
      isFormValid = false
      temp.email = `${t('email_empty')}!`
    }

    if (!email.includes('@') && email.trim().length !== 0) {
      isFormValid = false
      temp.email = `${t('invalid_email')}!`
    }

    if (password !== confirmPassword) {
      isFormValid = false
      temp.confirmPassword = `${t('passwords_match')}!`
    }
    if (password.trim().length === 0) {
      isFormValid = false
      temp.password = `${t('password_empty')}!`
    }
    if (confirmPassword.trim().length === 0) {
      isFormValid = false
      temp.confirmPassword = `${t('password_empty')}!`
    }

    setErrorData({ ...temp })
    return isFormValid
  }

  const handleRegister = async () => {
    if (validateInfo()) {
      signUpCall({
        data: {
          email_id: email,
          password: password,
          name,
          phone,
          referral_code: '',
        },
      })
    }
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  let subscriptionData = {
    data679908a429ecdaf7aed8f23f: {
      img: `public/common-template-assets/svtv-withads-img179787132.png`,
      price: 'USD $5.99',
      duration: 'month',
      plan_duration: 'Monthly Subscription',
      other_info: ['with ads', 'full library'],
      description: `Start your 1 month Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
    data6799ddd829ecdaf7aed8f247: {
      img: `public/common-template-assets/svtv-plus-withoutads-img179787132.png`,
      price: 'USD $9.99',
      duration: 'month',
      plan_duration: 'Monthly Subscription',
      other_info: ['without ads', 'full library', 'Live TV'],
      description: `Start your 1 month Ad Free Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
    data679def2629ecdaf7aed8f269: {
      img: `public/common-template-assets/svtv-yearly-withads-img179787132.png`,
      price: 'USD $49.99',
      duration: 'year',
      plan_duration: 'Yearly Subscription',
      other_info: ['with ads', 'full library', 'Live TV'],
      description: `Start your 1 year Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
    data679def6629ecdaf7aed8f26a: {
      img: `public/common-template-assets/svtv-yearly-withoutads-img172413354.png`,
      price: 'USD $59.99',
      duration: 'year',
      plan_duration: 'Yearly Subscription',
      other_info: ['without ads', 'full library', 'Live TV'],
      description: `Start your 1 year Ad Free Subscription with the SVTV Network, your go-to destination to watch movies, shows, sports, documentaries, series, and live events!`,
    },
  }

  return (
    <div style={{ background: '#000' }} className="w-full h-full px-6 xl:px-[8.489%] pt-10 pb-20">
      <div
        className={`relative w-full h-full flex flex-col xl:flex-row justify-center items-end xl:justify-between`}
      >
        <div className="w-full md:w-[400px] xl:w-[667px] ">
          <div>
            <CustomImage
              imageUrl={getTemplateImage(
                `public/common-template-assets/svtv-footer-logo1789782343.png`,
              )}
              imgClass="w-[110px] xl:w-[194px] cursor-pointer"
              imgStyle={{ backgroundColor: 'transparent', aspectRatio: '1.8644' }}
              loaderClass="w-[110px] xl:w-[194px] cursor-pointer"
              loaderStyle={{ background: '#5A5A5A50', aspectRatio: '1.8644' }}
              imgAlt={'Logo'}
            />
          </div>
          <p className="mb-0 mt-10 text-white xl:text-[25px]">Account details</p>
          <p className="mb-0 mt-5 " style={{ color: '#dcdcdc' }}>
            Already have an account ?{' '}
            <span
              style={{ color: '#ff320f' }}
              onClick={() => {
                navigate('/login')
              }}
              className="cursor-pointer"
            >
              Log in
            </span>
          </p>
          <div className="w-full mt-4 xl:mt-10" style={{ color: '#dcdcdc' }}>
            <p className="mb-0  ">
              Email <span className="text-red-600">*</span>
            </p>
            <input
              type="email"
              name="email"
              value={email}
              placeholder="example@mail.com"
              onChange={(e) => handleChange(e, setEmail)}
              style={{
                background: '#1c1c1c',
                color: 'white',
                border: `0.5px solid #585858`,
              }}
              className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
            />
            {errorData?.email && (
              <div className="error-message">
                <p>{errorData.email}</p>
              </div>
            )}
            <p className="mb-0 mt-4 ">
              Full Name <span className="text-red-600">*</span>
            </p>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => handleChange(e, setName)}
              style={{
                background: '#1c1c1c',
                color: 'white',
                border: `0.5px solid #585858`,
              }}
              className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
            />
            {errorData?.email && (
              <div className="error-message">
                <p>{errorData.email}</p>
              </div>
            )}
            <p className="mb-0 mt-4 ">Phone Number</p>
            <input
              type="number"
              name="phone"
              value={phone}
              onChange={(e) => handleChange(e, setPhone)}
              style={{
                background: '#1c1c1c',
                color: 'white',
                border: `0.5px solid #585858`,
              }}
              className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
            />
            <p className="mb-0 mt-4 ">
              Password <span className="text-red-600">*</span>
            </p>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => handleChange(e, setPassword)}
              style={{
                background: '#1c1c1c',
                color: 'white',
                border: `0.5px solid #585858`,
              }}
              className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
            />
            {errorData?.password && (
              <div className="error-message">
                <p>{errorData.password}</p>
              </div>
            )}
            <p className="mb-0 mt-4 ">
              Confirm Password <span className="text-red-600">*</span>
            </p>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => handleChange(e, setConfirmPassword)}
              style={{
                background: '#1c1c1c',
                color: 'white',
                border: `0.5px solid #585858`,
              }}
              className=" text-sm lg:text-base rounded block w-full p-1 mt-1 focus:outline-none "
            />
            {errorData?.confirmPassword && (
              <div className="error-message">
                <p>{errorData.confirmPassword}</p>
              </div>
            )}
            <div className="flex items-center mt-4 ">
              <div className="flex items-center">
                <input
                  id="remember"
                  type="checkbox"
                  defaultChecked={true}
                  className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                />
              </div>
              <label htmlFor="remember" style={{ color: '#fff' }} className="ml-2">
                Yes, I agree to the <span style={{ color: '#ff531f' }}>Terms & Conditions</span>
              </label>
            </div>
            <div className={'w-full flex justify-center'}>
              <button
                style={{
                  backgroundImage: 'linear-gradient(to right, #ff6b2b 10%, #ff320f 96%)',
                  color: '#fff',
                }}
                className="w-full mt-10 font-bold py-2 rounded focus:outline-none"
                onClick={handleRegister}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <div
          className="w-full md:w-[430px] mt-10 xl:mt-0"
          style={{
            backgroundImage: 'linear-gradient(to bottom, #111, #1c1c1c)',
          }}
        >
          <CustomImage
            imageUrl={getTemplateImage(subscriptionData[`data${planId}`]?.img)}
            imgClass="w-full cursor-pointer"
            imgStyle={{ backgroundColor: 'transparent', aspectRatio: '430/249' }}
            loaderClass="w-full cursor-pointer"
            loaderStyle={{ background: '#5A5A5A50', aspectRatio: '430/249' }}
            imgAlt={'Logo'}
          />

          <div className="flex h-full w-full flex-col items-center justify-between">
            <div className="w-full px-[30px]">
              <p style={{ color: '#f2602d' }} className="text-xl text-left w-full mt-3">
                {subscriptionData[`data${planId}`]?.plan_duration}
              </p>
              <div className="w-full text-white mt-2.5">
                {subscriptionData[`data${planId}`]?.other_info.map((ele, idx) => {
                  return (
                    <div className="flex items-center xl:text-lg 2xl:text-xl mt-2" key={idx}>
                      <img src={TickIcon} alt="TickIcon" className="mr-1" />
                      {ele}
                    </div>
                  )
                })}
              </div>
              <div className="my-4 flex flex-col items-center w-full">
                <p style={{ color: '#959595' }} className="text-sm mb-0">
                  {subscriptionData[`data${planId}`]?.description}
                </p>
              </div>
            </div>
            <div
              style={{ borderTop: '1px solid #363636' }}
              className="mt-4 mb-4 text-white w-full flex justify-between px-[30px]"
            >
              <div className="mt-4">
                <p className="text-[22px]">Total</p>
                <p style={{ color: '#959595' }} className="text-sm mt-1">
                  (Inclusive taxes)
                </p>
              </div>
              <div className="mt-4 flex flex-col items-end">
                <p className="text-[22px]"> {subscriptionData[`data${planId}`]?.price}</p>
                <p style={{ color: '#959595' }} className="text-sm mt-1">
                  /{subscriptionData[`data${planId}`]?.duration}
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-7 mb-4">
              <img src={LockIcon} alt="LockIcon" className="LockIcon" />
              <p style={{ color: '#e1e1e1' }} className="text-sm ml-2">
                Secure checkout
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterPlan

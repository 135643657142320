import React, { useState } from 'react'
import TickIcon from '../../assets/orange-svtv-tick.svg'
import CustomImage from '../customImage/customImage'
import useTemplateHook from '../template/templateHook'
import { useNavigate } from 'react-router'

const SvtvSubscriptionCards = () => {
  const { getTemplateImage } = useTemplateHook()
  const [hoverIndex, setHoverIndex] = useState('')
  const navigate = useNavigate()

  let subPlans = [
    {
      logo: 'public/common-template-assets/svtv-logo179874132.png',
      price: '$5.99/month',
      other_info: ['with ads', 'full library'],
      id: '679908a429ecdaf7aed8f23f',
    },
    {
      logo: 'public/common-template-assets/svtv-plus-logo173244589.png',
      price: '$9.99/month',
      other_info: ['without ads', 'full library', 'Live TV'],
      id: '6799ddd829ecdaf7aed8f247',
    },
    {
      logo: 'public/common-template-assets/svtv-logo179874132.png',
      price: '$49.99/year',
      other_info: ['with ads', 'full library', 'Live TV'],
      id: '679def2629ecdaf7aed8f269',
    },
    {
      logo: 'public/common-template-assets/svtv-plus-logo173244589.png',
      price: '$59.99/year',
      other_info: ['without ads', 'full library', 'Live TV'],
      id: '679def6629ecdaf7aed8f26a',
    },
  ]

  const handleSubRedirect = (id) => {
    navigate(`/register-plan/${id}`)
  }
  return (
    <div
      style={{ color: '#fff' }}
      className="w-full xl:px-[8.489%] bg-black xl:bg-[#0d0d0d] py-[28px]"
    >
      <div className=" flex flex-col lg:flex-row justify-between items-center">
        <div className=" xl:w-1/3 flex justify-center items-center xl:text-lg 2xl:text-xl">
          <img src={TickIcon} alt="TickIcon" className="mr-1" />
          24/7 Unlimited
        </div>
        <div className="my-2.5 xl:my-0 xl:w-1/3 flex justify-center items-center xl:text-lg 2xl:text-xl">
          <img src={TickIcon} alt="TickIcon" className="mr-1" />
          Watch on your TV, laptop, phone, or tablet
        </div>
        <div className=" xl:w-1/3 flex justify-center items-center xl:text-lg 2xl:text-xl">
          <img src={TickIcon} alt="TickIcon" className="mr-1" />
          New content added monthly
        </div>
      </div>
      <div className="flex flex-col items-center lg:flex-row justify-between mt-4 xl:mt-12 2xl:mt-20">
        {subPlans.map((info, index) => {
          return (
            <div
              className={`${
                hoverIndex === index && 'svtv-subscription-card'
              } w-[356px] lg:w-[24.3%] aspect-square bg-black cursor-pointer flex flex-col items-center justify-between pt-[48px] pb-[30px] xl:pb-0 xl:pt-[2.395vw]`}
              key={index}
              onMouseOver={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex('')}
            >
              <div className="flex w-full flex-col items-center">
                <CustomImage
                  imageUrl={getTemplateImage(info?.logo)}
                  imgClass={` h-7 xl:h-[37px] `}
                  loaderClass=""
                  loaderStyle={{
                    background: '#5A5A5A50',
                  }}
                  imgAlt={'class img'}
                />
                <p className="text-[38px] xl:text-[2.395vw] text-center font-bold mt-2">
                  {info.price}
                </p>
                {info.other_info.map((ele, idx) => {
                  return (
                    <div
                      className="flex items-center xl:text-lg 2xl:text-xl mt-3 xl:mt-1 2xl:mt-[11px]"
                      key={idx}
                    >
                      <img src={TickIcon} alt="TickIcon" className="mr-1" />
                      {ele}
                    </div>
                  )
                })}
              </div>
              <div
                className="w-[199px] flex justify-center items-center rounded-full cursor-pointer h-[40px] mb-2 2xl:mb-6"
                style={{
                  color: '#fff',
                  backgroundImage: 'linear-gradient(to right, #ec4614 9%, #f97607 90%)',
                }}
                onClick={() => handleSubRedirect(info.id)}
              >
                {`SUBSCRIBE NOW`}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SvtvSubscriptionCards

import React, { useState } from 'react'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'

const SvtvFooter = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  theme,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const {
    customNavigate,
    getTemplateImage,
    TGLogo,
    YTLogo,
    BitchuteLogo,
    RumbleLogo,
    BoxcastLogo,
    TikTokLogo,
    SVTVFacebookIcon,
    SVTVInstaIcon,
    SVTVLinkedinIcon,
    SVTVTwitterIcon,
    getExternalLink,
  } = useTemplateHook()

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const socialIcons = {
    facebook: SVTVFacebookIcon,
    twitter: SVTVTwitterIcon,
    instagram: SVTVInstaIcon,
    linkedin: SVTVLinkedinIcon,
    telegram: TGLogo,
    youtube: YTLogo,
    bitchute: BitchuteLogo,
    tiktok: TikTokLogo,
    rumble: RumbleLogo,
    boxcast: BoxcastLogo,
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <footer
      className={`${isEdit && hoverState && 'component-hover-class'}`}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', '#191919'),
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onEdit={() => handleModal()}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div className="xl:px-[10%] 2xl:px-[15%] pt-14 pb-14 xl:pt-[88px] xl:pb-[94px] relative ">
        <div className="flex xl:flex-row flex-col items-center lg:items-stretch xl:justify-between">
          <div className="w-full xl:w-[30%]">
            <div
              className=" flex justify-center lg:justify-start"
              onClick={() => customNavigate('DIRECT', '/home', isEdit)}
            >
              <CustomImage
                imageUrl={getTemplateImage(data?.image_s3key)}
                imgClass="w-[110px] xl:w-[194px] cursor-pointer"
                imgStyle={{ backgroundColor: 'transparent', aspectRatio: '1.8644' }}
                loaderClass="w-[110px] xl:w-[194px] cursor-pointer"
                loaderStyle={{ background: '#5A5A5A50', aspectRatio: '1.8644' }}
                imgAlt={'Logo'}
              />
            </div>
            <div
              className={`flex justify-center lg:justify-start  text-center xl:text-left text-[28px] xl:text-[40px]  mt-[26px] ${getStyleValue(
                'heading',
                'font_format',
                'font-bold',
              )}`}
              style={{
                color: getStyleValue('heading', 'color', '#ffffff'),
                fontFamily: getStyleValue('heading', 'font', ''),
              }}
            >
              <p className="mb-0  w-3/4 sm:w-full">{data?.heading}</p>
            </div>
            <div
              className={`flex justify-center lg:justify-start text-center xl:text-left  mt-[26px] ${getStyleValue(
                'sub_heading',
                'font_format',
                '',
              )}`}
              style={{
                color: getStyleValue('sub_heading', 'color', '#ffffff'),
                fontFamily: getStyleValue('sub_heading', 'font', ''),
              }}
            >
              {data?.sub_heading}
            </div>
          </div>
          <div className="w-full xl:w-[35%] flex lg:flex-row flex-col items-center lg:items-start justify-center lg:justify-between mt-[32px] xl:mt-6">
            <div className={`w-full xl:w-1/2 lg:text-left  ${!isEdit && 'text-center'}`}>
              <p
                style={{
                  color: getStyleValue('sitemap_header', 'color', '#fff'),
                  fontFamily: getStyleValue('sitemap_header', 'font', ''),
                }}
                className={`${getStyleValue(
                  'sitemap_header',
                  'font_format',
                  'font-bold',
                )}  xl:mb-8 mb-[16px]`}
              >
                {data?.column_one_menus?.header_text}
              </p>
              {data?.column_one_menus?.sub_menu_items.map((page, index) => (
                <div
                  style={{
                    color: getStyleValue('sitemap_submenu', 'color', '#b7b7b7'),
                    fontFamily: getStyleValue('sitemap_submenu', 'font', ''),
                  }}
                  className={''}
                  key={index}
                >
                  <p
                    className={`cursor-pointer text-sm xl:mb-5 mb-[16px] lg:w-fit ${getStyleValue(
                      'sitemap_submenu',
                      'font_format',
                      '',
                    )}`}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
            </div>
            <div
              className={`w-full xl:w-1/2 lg:text-left  ${
                !isEdit && 'text-center'
              }  mt-[32px] xl:mt-0`}
            >
              <p
                style={{
                  color: getStyleValue('sitemap_header', 'color', '#fff'),
                  fontFamily: getStyleValue('sitemap_header', 'font', ''),
                }}
                className={`${getStyleValue(
                  'sitemap_header',
                  'font_format',
                  'font-bold',
                )} xl:mb-8 mb-[16px]`}
              >
                {data?.column_two_menus?.header_text}
              </p>
              {data?.column_two_menus?.sub_menu_items.map((page, index) => (
                <div
                  style={{
                    color: getStyleValue('sitemap_submenu', 'color', '#b7b7b7'),
                    fontFamily: getStyleValue('sitemap_submenu', 'font', ''),
                  }}
                  className={''}
                  key={index}
                >
                  <p
                    className={`cursor-pointer text-sm xl:mb-5 mb-[16px] lg:w-fit ${getStyleValue(
                      'sitemap_submenu',
                      'font_format',
                      '',
                    )}`}
                    title={page.text}
                    onClick={() => handleRedirect(page.link)}
                  >
                    {page?.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className=" w-full xl:w-[21%] text-center mt-[32px] xl:mt-6" style={{}}>
            <div className={`flex flex-col items-center text-center w-full`}>
              <p
                style={{
                  color: getStyleValue('social_media_text', 'color', '#fff'),
                  fontFamily: getStyleValue('social_media_text', 'font', ''),
                }}
                className={`${getStyleValue('social_media_text', 'font_format', 'font-bold')} mb-0`}
              >
                {data?.social_handles_text}
              </p>
              <div
                className="mt-5 xl:mt-10 lg:grid"
                style={{
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gridTemplateRows: 'repeat(2 ,max-content)',
                  gridColumnGap: '0px',
                  gridRowGap: '6px',
                }}
              >
                {[...data?.social_handles].map((item, index) => (
                  <div
                    className="w-10 xl:w-[51px] aspect-square inline-block"
                    key={index}
                    style={{ marginLeft: index ? '23px' : '' }}
                  >
                    <a href={getExternalLink(item.url)} target="_blank" rel="noreferrer">
                      <img className="w-full" src={socialIcons[item.name]} alt={`${item.name}`} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <p
              className={` mt-[76px] xl:mt-[91px] ${getStyleValue(
                'copyright',
                'font_format',
                '',
              )} mb-0`}
              style={{
                fontFamily: getStyleValue('copyright', 'font', ''),
                textAlign: getStyleValue('copyright', 'alignment', ''),
                color: getStyleValue('copyright', 'color', '#dfdada'),
              }}
            >
              &copy; {data?.copyright_text}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default withTheme(SvtvFooter)

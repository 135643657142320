import React from 'react'
import CustomImage from '../customImage/customImage'
import useTemplateHook from '../template/templateHook'
import { useNavigate } from 'react-router'

const SvtvPromoBanner = (data) => {
  const { getTemplateImage } = useTemplateHook()
  const navigate = useNavigate()

  return (
    <div className="w-full relative aspect-[0.8724] xl:aspect-[2.394]">
      <CustomImage
        imageUrl={getTemplateImage(data?.main_img)}
        imgClass={`hidden lg:block z-0 w-full absolute top-0 xl:aspect-[2.394] `}
        //   imgStyle={{ aspectRatio: '2.394' }}
        loaderClass="z-0 w-full h-full object-cover absolute"
        loaderStyle={{
          background: '#5A5A5A50',
        }}
        imgAlt={'class img"'}
      />
      <CustomImage
        imageUrl={getTemplateImage(data?.mobile_img)}
        imgClass={`block lg:hidden z-0 w-full absolute top-0 aspect-[0.8724] `}
        //   imgStyle={{ aspectRatio: '2.394' }}
        loaderClass="z-0 w-full h-full object-cover absolute"
        loaderStyle={{
          background: '#5A5A5A50',
        }}
        imgAlt={'class img"'}
      />
      <div
        className={` xl:px-[8.489%]  w-full h-full z-10 flex items-center justify-center lg:justify-start text-center lg:text-left`}
      >
        <div
          style={{
            color: '#fff',
          }}
          className=" z-10 w-[88.46%] lg:w-[589px] flex flex-col items-center lg:items-start"
        >
          <h1
            className={`font-bold text-[28px] leading-tight md:text-[60px] px-10 lg:px-0 lg:mr-8`}
          >
            {data.heading}
          </h1>
          <p className={`mt-3 md:text-[22px] leading-tight font-normal `}>{data.para_1}</p>
          <p className={`mt-[4px] md:text-[22px] leading-tight font-normal `}>{data.para_2}</p>
          <CustomImage
            imageUrl={getTemplateImage(data?.other_img)}
            imgClass={` ${data.other_img_class} mt-2.5`}
            //   imgStyle={{ aspectRatio: '2.394' }}
            loaderClass=""
            loaderStyle={{
              background: '#5A5A5A50',
            }}
            imgAlt={'class img'}
          />
          <div
            className="w-[199px] lg:w-[148px]  flex justify-center items-center bg-white rounded-full cursor-pointer h-[40px] font-bold  mt-6 lg:mt-10"
            style={{ color: '#ff7700', border: 'solid 3px #f70' }}
            onClick={() => navigate(`/pricing-plans`)}
          >
            {data.button_text}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SvtvPromoBanner

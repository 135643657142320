import React, { useState } from 'react'
import BgImg from '../../assets/landing-page-img.webp'
import MobileImg from '../../assets/landing-page-img-mobile.webp'
import Logo from '../../assets/sv-tv-logo.png'
import { useNavigate } from 'react-router'
import { raiseToast } from '../../utils/helper'
import SvtvBanner from '../../components/template/default/svtvBanner'
import SvtvPromoBanner from '../../components/svtvLandingPageComponents/svtvPromoBanner'
import SvtvSubscriptionCards from '../../components/svtvLandingPageComponents/svtvSubscriptionCards'

const LandingPage = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  const handleEmailClick = () => {
    if (email) {
      navigate(`/pricing-plans?email=${email}`)
    } else {
      raiseToast('error', 'Enter email first')
    }
  }

  let componentProps = {
    componentData: {
      id: '0e16250-c3be-f162-dc8-c40a14cbd3',
      component_key: 'decomposedBannersPlayer',
      category: 'banner_carousel',
      config: {
        TOP_LEFT: 'justify-start text-left',
        TOP_CENTER: 'justify-center text-center',
        TOP_RIGHT: 'justify-end text-right',
      },
      data: {
        style_config: {
          text: {
            title: 'Text',
            key: 'text',
            data: [
              {
                key: 'font',
                name: 'Font',
                type: 'FONT',
                value: '',
              },
              {
                key: 'color',
                name: 'Color',
                type: 'COLOR',
                value: '',
              },
              {
                key: 'font_format',
                name: 'Font Format',
                type: 'FONT_FORMAT',
                value: '',
              },
              {
                key: 'alignment',
                name: 'Alignment',
                type: 'FONT_ALIGNMENT',
                value: '',
              },
            ],
          },
          button: {
            title: 'Button',
            key: 'button',
            data: [
              {
                key: 'font',
                name: 'Font',
                type: 'FONT',
                value: '',
              },
              {
                key: 'color',
                name: 'Color',
                type: 'COLOR',
                value: '',
              },
              {
                key: 'gradient',
                name: 'Gradient Direction',
                type: 'GRADIENT',
                value: '',
              },
              {
                key: 'primary_color',
                name: 'Primary Color',
                type: 'COLOR',
                value: '#ec4614',
              },
              {
                key: 'secondary_color',
                name: 'Secondary Color',
                type: 'COLOR',
                value: '#f97607',
              },
            ],
          },
        },
        auto_slide: true,
        slide_data: [
          {
            image_s3key: 'public/common-template-assets/svtv-banner-img.png',
            mobile_image_s3key:
              'public/common-template-assets/svtv-promo-banner-mobile1798741351.png',
            content: {
              heading: 'SVTV STREAMING',
              sub_heading: `Catch the biggest blockbusters and amplify diverse voices with SVTV Network.`,
              position: 'TOP_LEFT',
            },
            image_swimlane: [
              {
                image_s3key: 'public/common-template-assets/one-four-three179789813.png',
                link: {
                  type: '',
                  data: {},
                },
              },
              {
                image_s3key: 'public/common-template-assets/innocent-boy179782342.png',
                link: {
                  type: '',
                  data: {},
                },
              },
              {
                image_s3key: 'public/common-template-assets/avacado-toast1723482342.png',
                link: {
                  type: '',
                  data: {},
                },
              },
              {
                image_s3key: 'public/common-template-assets/black-girl-magic-89798.png',
                link: {
                  type: '',
                  data: {},
                },
              },
              {
                image_s3key: 'public/common-template-assets/trace-season118986.png',
                link: {
                  type: '',
                  data: {},
                },
              },
              {
                image_s3key: 'public/common-template-assets/tooth-4-tooth1712318986.png',
                link: {
                  type: '',
                  data: {},
                },
              },
            ],

            cta_button: {
              enabled: true,
              text: 'Start streaming now!',
            },
            link: {
              type: '',
              data: {},
            },
            player: {
              type: '',
              data: {},
            },
          },
        ],
      },
    },
    modifyData: () => {},
    setModalState: () => {},
    isEdit: false,
    onSwap: () => {},
    onDelete: () => {},
    onCopy: () => {},
    onAdd: () => {},
  }

  let representationProps = {
    main_img: 'public/common-template-assets/representation-on-go-img.png',
    mobile_img: 'public/common-template-assets/representation-on-go-mobile-img.png',
    heading: 'REPRESENTATION ON THE GO.',
    para_1:
      'Live TV, sports, and diverse entertainment your way. Stream with SVTV Network wherever you are. At home or the go with the SVTV Network mobile & TV apps.',
    para_2: 'Download Today!',
    other_img: 'public/common-template-assets/apps-img7891323254.png',
    other_img_class: 'w-[323px] lg:w-[542px]',
    button_text: 'Try it Now',
  }

  let liveSportsProps = {
    main_img: 'public/common-template-assets/live-sports-game-on-img.png',
    mobile_img: 'public/common-template-assets/live-sports-game-on-mobile-img.png',
    heading: `LIVE SPORTS GAME ON!`,
    para_1:
      'Sports bring the world together, and SVTV Network Sports amplifies the voices of the underrepresented - on the field, the court, and beyond. Watch live games and events, wherever you are, at home or on the go.',
    para_2: 'Watch the sports you love with SVTV + Live TV.',
    other_img: 'public/common-template-assets/teams-img1231321321.png',
    other_img_class: 'w-[180px] lg:w-[283px]',
    button_text: 'Try it Now',
  }

  return (
    <>
      <div className="w-full h-full relative">
        <img src={BgImg} alt="BgImg" className="w-full hidden xl:block" />
        <img src={MobileImg} alt="BgImg" className="w-full block xl:hidden" />
        <div
          style={{}}
          className="absolute top-0 right-0 w-full flex justify-center lg:justify-end items-center mt-16 md:mt-20 md:pr-36 z-10"
        >
          <div
            className="w-[95%] md:w-[213px] flex justify-center items-center rounded-full cursor-pointer h-[48px] md:h-[54px] font-bold "
            style={{
              color: '#e1e1e1',
              border: 'solid 1px #fff',
              background: 'rgba(255, 255, 255, 0.2)',
            }}
            onClick={() => navigate(`/login`)}
          >
            {`Sign in`}
          </div>
        </div>
        <div className="absolute top-0 w-full h-full flex justify-center items-center">
          <div className=" w-[95%] md:w-[855px] flex flex-col items-center justify-center text-white">
            <img src={Logo} alt="BgImg" className="w-[189px] md:w-[380px]" />
            <p className="mt-5 md:mt-9 text-center font-bold text-[28px] md:text-[60px] leading-tight">
              ENTERTAINMENT THAT AMPLIFIES YOU.
            </p>
            <p className="mt-4 md:mt-2 text-center md:text-[22px] md:px-3">
              The search for Representation is over! Your Story, Your Voice, Your Entertainment -
              Now Movies, Seies, Sports, and Live TV. Empowering Voices, Streaming Everywhere. Plan
              start at $5.99/month with ads or find the perfect plan for you. Cancel Anytime.
            </p>
            <p className="mt-8 md:mt-9 text-center md:text-[22px]">
              Ready to watch? Enter your email to create or restart your subscription.
            </p>
            <div className="mt-8 md:mt-9 flex flex-col md:flex-row justify-center items-center w-full">
              <input
                className="rounded-full w-full md:w-[402px] h-[48px] md:h-[54px] md:text-xl px-4 text-white focus:outline-none"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                  border: 'solid 1px #fff',
                }}
              />
              <div
                className="w-full md:w-[213px] flex justify-center items-center bg-white rounded-full cursor-pointer h-[48px] md:h-[54px] font-bold md:ml-6 mt-6 md:mt-0"
                style={{ color: '#ff7700', border: 'solid 3px #f70' }}
                onClick={handleEmailClick}
              >
                {`Get Started >`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SvtvBanner {...componentProps} />
      <SvtvPromoBanner {...representationProps} />
      <SvtvPromoBanner {...liveSportsProps} />
      <SvtvSubscriptionCards />
    </>
  )
}

export default LandingPage

import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleSvtvBanners.js'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import './svtvBannerStyles.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
// import DecomposedSwimlane from './decomposedSwimlane'
import { isEmpty } from 'lodash'
import PassimPlayer from '../rosemary/passimPlayer'
import { useNavigate } from 'react-router'

const SvtvBanner = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id } = componentData
  const navigate = useNavigate()
  const {
    //  customNavigate,
    getTemplateImage,
    PrevArrow,
    NextArrow,
    LG_SIZE,
  } = useTemplateHook()

  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    navigate(`/pricing-plans`)
    // customNavigate('INDIRECT', linkObj, isEdit)
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'flex-start',
          background: `transaprent`,
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '48%',
          right: '3.6%',
          content: `url(${NextArrow})`,
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: `transaprent`,
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '48%',
          left: '3.6%',
          content: `url(${PrevArrow})`,
          zIndex: '10',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }
  useEffect(() => {
    setSetting({
      arrows: window.innerWidth > LG_SIZE,
      nextArrow: <SampleNextArrow transform={128} />,
      prevArrow: <SamplePrevArrow transform={128} />,
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      overflow: true,
      dots: false,
      adaptiveHeight: true,
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }
  return (
    <div
      style={{ background: theme.c1 }}
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'} z-10 `}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
          position={'top-20 right-4'}
          menuPosition={'top-24 right-4'}
        />
      )}
      <div
        className=" w-full h-full bg-cover bg-center hero-section z-0 "
        style={{
          overflow: 'visible',
        }}
      >
        <SliderWrapper className="relative " isEdit={isEdit} hoverState={hoverState}>
          <Slider ref={sliderRef} {...setting}>
            {data?.slide_data.map((slide, idx) => (
              <div style={{ width: '100%', height: '100%', marginTop: '0px' }} key={idx}>
                <div
                  className={`relative w-full flex flex-col img-gradient z-0 aspect-[0.6] xl:block xl:aspect-[2.394]`}
                  //   style={{ aspectRatio: '2.394' }}
                >
                  {isEdit || !slide?.player?.type ? (
                    <>
                      <div className="absolute top-0 hidden xl:block xl:aspect-[2.394] w-full">
                        <CustomImage
                          imageUrl={getTemplateImage(slide?.image_s3key)}
                          imgClass={` z-0 w-full h-full`}
                          loaderClass=" z-0 w-full h-full"
                          loaderStyle={{
                            background: '#5A5A5A50',
                          }}
                          imgAlt={'class img"'}
                        />
                      </div>
                      <div className="absolute top-0 block xl:hidden aspect-[0.6] w-full">
                        <CustomImage
                          imageUrl={getTemplateImage(slide?.mobile_image_s3key)}
                          imgClass={` z-0 w-full h-full `}
                          loaderClass=" z-0 w-full h-full"
                          loaderStyle={{
                            background: '#5A5A5A50',
                          }}
                          imgAlt={'class img"'}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="absolute -z-10 w-full h-full">
                      <PassimPlayer
                        data={slide?.player}
                        poster={getTemplateImage(slide?.image_s3key)}
                        controlBar={false}
                        isBanner={true}
                        iconPositionClass={'decomposed-banner-player-icon'}
                      />
                    </div>
                  )}

                  <div
                    className={`px-[5%] xl:px-[8.489%] w-full z-10 flex justify-center xl:justify-start text-center xl:text-left`}
                  >
                    <div className="mt-[128px]  xl:mt-[4vw] 2xl:mt-[5.677vw] z-10 xl:w-[589px] drop-shadow ">
                      <h1
                        style={{
                          color: getStyleValue('text', 'color', theme.c7),
                          fontFamily: getStyleValue('text', 'font', ''),
                          textAlign: getStyleValue('text', 'alignment', ''),
                        }}
                        className={`font-bold text-[28px] leading-tight md:text-[60px] ${getStyleValue(
                          'text',
                          'font_format',
                          'font-semibold',
                        )}  `}
                      >
                        {slide.content.heading}
                      </h1>
                      <p
                        style={{
                          color: getStyleValue('text', 'color', theme.c7),
                          fontFamily: getStyleValue('text', 'font', ''),
                          textAlign: getStyleValue('text', 'alignment', ''),
                        }}
                        className={`mt-[4px] md:text-[22px] leading-tight font-normal ${getStyleValue(
                          'text',
                          'font_format',
                          '',
                        )}`}
                      >
                        {slide.content.sub_heading}
                      </p>
                      {slide.cta_button.enabled && (
                        <button
                          className="font-semibold mt-6 py-[8px] px-9 border-none tracking-normal rounded-full "
                          style={{
                            color: getStyleValue('button', 'color', theme.c7),
                            fontFamily: getStyleValue('button', 'font', ''),
                            backgroundImage: `linear-gradient(to ${getStyleValue(
                              'button',
                              'gradient',
                              'right',
                            )}
                                , ${getStyleValue(
                                  'button',
                                  'primary_color',
                                  theme.c3,
                                )} 9%,  ${getStyleValue(
                              'button',
                              'secondary_color',
                              theme.c3,
                            )}  90%)`,
                          }}
                          onClick={() => handleRedirect(slide.link)}
                        >
                          {slide.cta_button.text}
                        </button>
                      )}
                    </div>
                  </div>

                  <div
                    className="px-[5%] xl:px-[10%] 2xl:px-[15%] mt-8 xl:mt-[4vw] 2xl:mt-[7vw] w-full "
                    style={{ zIndex: '100' }}
                  >
                    <div className="relative grid grid-cols-3 xl:grid-cols-6 gap-3 xl:gap-5 justify-between">
                      {!isEmpty(slide.image_swimlane) &&
                        slide.image_swimlane.map((info, index) => {
                          return (
                            <div
                              className="relative cursor-pointer"
                              onClick={() => handleRedirect(info?.link)}
                              key={index}
                            >
                              <CustomImage
                                imageUrl={getTemplateImage(info?.image_s3key)}
                                imgClass=" w-full"
                                imgStyle={{ zIndex: '-1' }}
                                loaderClass=" w-full"
                                loaderStyle={{ background: '#5A5A5A50' }}
                                imgAlt={'square Img'}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
      </div>

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(SvtvBanner)

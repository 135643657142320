import React, { useState } from 'react'
import EditMenu from '../editMenu'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import { isEmpty } from 'lodash'

const PromotionalLead = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id, config } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()

  const [hoverState, setHoverState] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
      className={`${
        isEdit && hoverState && 'component-hover-class'
      } w-full h-[153px] md:h-[60vh] bg-cover relative cursor-pointer`}
    >
      <CustomImage
        imageUrl={getTemplateImage(data?.image_s3key)}
        imgClass="absolute w-full h-full"
        loaderClass="w-full h-full absolute"
        loaderStyle={{
          background: '#5A5A5A50',
        }}
        imgAlt={'category'}
      />
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div
        className={`flex h-full flex-col ${
          config[data?.position]
        } md:p-6 p-[6px] xl:py-6 xl:px-[9.29vw] z-10`}
      >
        <h1
          style={{
            color: getStyleValue('heading', 'color', theme.c2),
            fontFamily: getStyleValue('heading', 'font', ''),
          }}
          className={`text-[18px] md:text-3xl lg:text-4xl 2xl:text-5xl mb-3 drop-shadow-md z-10  ${getStyleValue(
            'heading',
            'font_format',
            'font-bold',
          )}`}
        >
          {data?.heading}
        </h1>
        <p
          style={{
            color: getStyleValue('paragraph', 'color', theme.c5),
            fontFamily: getStyleValue('paragraph', 'font', ''),
          }}
          className={`text-[10px] sm:text-base w-full md:w-1/2 xl:w-[40%]  mb-6 z-10 ${getStyleValue(
            'paragraph',
            'font_format',
            'font-normal',
          )}`}
        >
          {data?.description}
        </p>
        {data?.cta_button?.enabled && (
          <div className="w-2/3 md:w-1/2 xl:w-[40%] flex justify-between items-center z-10">
            {data?.input_placeholder && (
              <input
                type="text"
                placeholder={data?.input_placeholder}
                className="border-0 py-[0.25rem] px-[0.25rem] md:p-2 md:px-3 w-[65%] text-[10px] sm:text-base rounded shadow-md shadow-gray-500"
                disabled={isEdit}
                style={{ background: getStyleValue('button', 'input_bg_color', '#fff') }}
              />
            )}

            <button
              style={{
                backgroundImage: `linear-gradient(to ${getStyleValue(
                  'button',
                  'gradient',
                  'left',
                )}, ${getStyleValue('button', 'primary_color', theme.c5)}, ${getStyleValue(
                  'button',
                  'secondary_color',
                  theme.c5,
                )})`,
                color: getStyleValue('button', 'text_color', theme.c7),
              }}
              className={` border-0 py-[0.25rem] md:p-2 w-[32%] rounded shadow-md shadow-gray-700 text-[10px] sm:text-base`}
              onClick={() => handleRedirect(data?.cta_button?.link)}
              disabled={isEdit}
            >
              {data?.cta_button?.text}
            </button>
          </div>
        )}

        {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
      </div>
    </div>
  )
}

export default withTheme(PromotionalLead)
